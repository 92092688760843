.content{
    position: relative;
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 35px - 62px);
    padding-left: 65px;
    padding-top: 55px;
    display: flex;
    flex-wrap: wrap;
    &#home{
        padding-top: 105px;
    }
    .two-third{
        position: relative;
        width: calc(((100% + 65px) / 3) * 2 - 65px);
    }
    .one-third{
        position: relative;
        width: calc((100% + 65px) / 3);
    }
    .one-half{
        width: calc(50% - 12.5px);
        margin-right: 12.5px;
        & + .one-half{
            margin-right: 0;
            margin-left: 12.5px;
        }
    }
    h1{
        display: inline-block;
        padding-left: 50px;
        font-size: 24px;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 85px;
        margin: 0;
        height: 85px;
    }
    .separator{
        display: block;
        background: #666666;
        clear: both;
        height: 3px;
        width: 250px;
    }
    p{
        padding-left: 50px;
        font-size: 12px;
    }
    .grey{
        color: #666666;
    }
}
.hide{
    display: none;
}