header{
    height: 62px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    .logo{
        height: 100%;
        width: 65px;
        background: #000;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 46px;
        }
    }
    .user_menu{
        height: 100%;
        width: calc((100% / 3) * 2 - 65px);
        display: flex;
        font-size: 12px;
        align-items: center;
        &.active{
            background: #000;
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                li{
                    padding-left: 50px;
                    a{
                        div{
                            display: inline-block;
                            width: 50px;
                            text-align: left;
                            img{
                                height: 20px;
                            }
                        }
                        color: #FFF;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .user_menu2{
        position: absolute;
        top: 100%;
        left: 65px;
        width: calc((100% / 3) * 2 - 65px);
        height: 55px;
        font-size: 12px;
        background: #FFF;
        ul{
            list-style: none;
            margin: 0;
            height: 100%;
            padding: 0 0 0 50px;
            align-items: center;
            display: flex;
            color: #000;
            li{
                padding: 0;
                margin: 0 25px 0 0;
                &:after{
                    content: '.';
                }
                a{
                    color: inherit;
                    text-decoration: inherit;
                    text-transform: uppercase;
                }
            }
        }
    }
    .main_menu{
        height: 100%;
        width: calc(100% / 3);
        background-color: #000;
        color: #FFF;
        padding-left: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        img{
            height: 25px;
        }
        button{
            height: 62px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            &.hamburger{
                transition-timing-function: linear;
                transition-duration: .15s;
                transition-property: opacity,filter;
                padding: 28px 15px;
                .hamburger-box{
                    position: relative;
                    display: inline-block;
                    width: 40px;
                    height: 24px;
                    .hamburger-inner{
                        transition-timing-function: cubic-bezier(.55,.055,.675,.19);
                        transition-duration: 75ms;
                        position: absolute;
                        width: 40px;
                        height: 4px;
                        transition-property: transform;
                        border-radius: 4px;
                        background-color: #fff;
                        &:before{
                            transition: top 75ms ease .12s,opacity 75ms ease;
                            top: -10px;
                            content: '';
                            display: block;
                            position: absolute;
                            width: 40px;
                            height: 4px;
                            border-radius: 4px;
                            background-color: #FFF;
                        }
                        &:after{
                            transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
                            bottom: -10px;
                            display: block;
                            content: "";
                            position: absolute;
                            width: 40px;
                            height: 4px;
                            border-radius: 4px;
                            background-color: #fff;
                        }
                    }
                    &:hover{
                        opacity: .7;
                    }       
                }
                &.is-active{
                    .hamburger-box .hamburger-inner{
                        transition-delay: .12s;
                        transition-timing-function: cubic-bezier(.215,.61,.355,1);
                        transform: rotate(45deg);
                        &:after{
                            bottom: 0;
                            transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
                            transform: rotate(-90deg);
                        }
                        &:before{
                            top: 0;
                            transition: top 75ms ease,opacity 75ms ease .12s;
                            opacity: 0;
                        }
                        
                    }
                }
            }
        }
        menu{
            position: absolute;
            left: 0;
            top: 100%;
            background-color: #000;
            width: 100%;
            //padding: 90px 70px 45px 50px;
            margin: 0;
            transition: .195s all ease;
            max-height: 0;
            height: auto;
            &.is-active{
                max-height: 1000px;
                padding: 90px 70px 45px 50px;
            }
            overflow: hidden;
            list-style: none;
            li{
                margin: 0;
                text-transform: uppercase;
                margin-top: 20px;
                a{
                    color: inherit;
                    text-decoration: none;;
                }
                &:last-child{
                    padding-top: 10px;
                    margin-top: 35px;
                    border-top: 1px solid #FFF;
                }
            }
        }
    }
}