@import "normalize-scss/sass/_normalize";
@include normalize();

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;1,400&display=swap');
*{
    font-family: 'Open Sans', sans-serif;
    letter-spacing: .05em;
    box-sizing: border-box;
}
img{
    max-width: 100%;
}
body {
    margin: 0;
    
    padding: 0;
    
    width: 100vw;
    height: 100vh;
    position: relative;
}
.flex{
    display: flex;
    &.wrap{
        flex-wrap: wrap;
    }
}
#app{
    border: 32px solid #FFF;
    background: #f3f3f3;
    width: 100vw;
    height: 100vh;
    position: relative;
}

@import "partials/header";
@import "partials/footer";
@import "partials/content";