footer{
    position: absolute;
    bottom: 0;
    left: 65px;
    width: calc((100% / 3) * 2 - 65px);
    height: 35px;
    background: #000;
    padding-left: 55px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #FFF;
    .copyright-links{
        display: flex;
        menu{
            list-style: none;
            margin:0;
            padding: 0 0 0 145px;
            li{
                margin:0 35px 0 0;
                display: inline;
                a{
                    color: inherit;
                    text-decoration: inherit;
                }
            }
        }
    }
    .social{
        display: flex;
        align-items: center;
        a{
            margin-left: 15px;
            img{
                height: 18px;
            }
        }
    }
}